.CovidCharts {
	margin: 10px 0px;
}

h4 {
	margin-bottom: 8px;
}

#wordCloudBox{
	
}
