.legend {
    line-height: 18px;
    color: #555;
	background-color: rgba(255, 255, 255, 0.8);
}
.legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
}