.MuiTableBody-root {
	word-wrap: break-word;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    word-break: break-all;
}

.MuiDialogContent-root {
	padding-top: 0px;
}