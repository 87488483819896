.sideMenu {
  display: grid;
  grid-template-areas:
    "smheader"
    "smform"
    "smcharts"
    "filters";
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  padding: 10px 20px;
  overflow-y: auto;
}

.sideMenu__header {
	margin: 10px 0;
  text-align: center;
}
