* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  padding: 0;
  margin: 0;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.app {
  display: flex;
  flex-flow: column;
  height: 100vh;
}
